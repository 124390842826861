import React, { useCallback } from 'react'
import clsx from 'clsx'
import { CheckboxProps } from './checkbox'

export type { CheckboxProps }

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  label,
  name,
  value,
  checked,
  disabled = false,
  description = null,
  isError = false,
  className,
  onChange,
  labelClassName,
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e, e.target.checked)
    },
    [value, onChange],
  )
  const handleClick = useCallback((e: React.MouseEvent<HTMLLabelElement>) => {
    e.stopPropagation()
  }, [])
  return (
    <div className="flex items-center w-fit">
      <label htmlFor={id ?? name} className="p-2" onClick={handleClick}>
        <input
          id={id || name}
          type="checkbox"
          checked={checked}
          name={name}
          value={value}
          disabled={disabled}
          onChange={handleChange}
          className={clsx(
            'w-4 h-4 rounded focus:ring-2',
            'bg-white checked:bg-primary-600 border-gray-300 focus:ring-gray-200',
            'dark:bg-slate-400 dark:border-slate-500 dark:focus:ring-slate-300 dark:ring-offset-slate-800',
          )}
        />
      </label>
      <div className="ml-1">
        <label
          htmlFor={id ?? name}
          className={clsx(
            'whitespace-normal ml-1 text-sm font-medium',
            labelClassName,
            {
              'text-gray-400 dark:text-slate-500': disabled,
              'text-gray-900 dark:text-slate-400 dark:text-gray-500': !disabled,
            },
          )}
        >
          {label}
        </label>
        {description && (
          <p
            className={clsx('whitespace-normal text-xs font-normal pl-1 mb-2', {
              'text-error': isError,
              'text-gray-500 dark:text-slate-400': !isError,
            })}
          >
            {description}
          </p>
        )}
      </div>
    </div>
  )
}

export default Checkbox
