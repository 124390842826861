import {
  Dialog,
  DialogTitle,
  Description as DialogDescription,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { ModalProvider, useModal } from './modal-context'
import clsx from 'clsx'
import React, { Fragment, PropsWithChildren } from 'react'
import { IconButton } from '~ui/IconButton'

type ModalProps = {
  isOpen: boolean
  onClose: () => void
  size?: 'small' | 'medium' | 'large'
  afterClose?: () => void
  afterOpen?: () => void
}

const Modal: React.FC<PropsWithChildren<ModalProps>> & {
  Title: React.FC<PropsWithChildren>
  Description: React.FC<PropsWithChildren>
  Body: React.FC<PropsWithChildren>
  Footer: React.FC<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
  >
} = ({ isOpen, onClose, size = 'medium', children, afterClose, afterOpen }) => {
  const handleClose = React.useCallback(
    (value: boolean) => {
      onClose()
    },
    [onClose],
  )
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[75]" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterEnter={afterOpen}
          afterLeave={afterClose}
        >
          <div className="fixed inset-0 bg-gray-700 bg-opacity-75 backdrop-blur-sm" />
        </TransitionChild>

        <div className="fixed inset-0 sm:inset-6 sm:overflow-hidden">
          <div className="flex min-h-full items-center justify-center sm:p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel
                className={clsx(
                  'relative',
                  'flex flex-col justify-start',
                  // 'overflow-auto',
                  'overflow-hidden',
                  'w-full max-w-full h-dvh h-full min-h-screen',
                  'sm:max-w-2xl sm:min-h-full',
                  'border sm:rounded-lg transform text-left align-middle shadow-xl transition-all',
                  'bg-white dark:bg-slate-800 border-transparent dark:border-slate-700 dark:shadow-slate-800',
                  {
                    'sm:max-w-md sm:h-96': size === 'small',
                    'sm:max-w-xl sm:h-[486px]': size === 'medium',
                    'sm:max-w-3xl lg:max-w-5xl sm:h-[calc(100vh-8rem)] sm:max-h-[95vh]':
                      size === 'large',
                  },
                )}
              >
                <ModalProvider close={onClose}>
                  <Close />
                  {/* <div
                    className={clsx(
                      'absolute inset-4 sm:inset-8 overflow-y-auto flex flex-col pb-12',
                    )}
                  > */}
                    {children}
                  
                </ModalProvider>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

const Close: React.FC = () => {
  const { close } = useModal()
  return (
    <div className="absolute top-4 right-4 z-10">
      <IconButton
        icon={<XMarkIcon className="w-6 h-6"></XMarkIcon>}
        className="bg-white dark:bg-slate-800 rounded-full"
        onClick={close}
      />
    </div>
  )
}

const Title: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <DialogTitle className="p-4 sm:p-8 flex items-center justify-between text-2xl dark:text-slate-400">
      {children}
    </DialogTitle>
  )
}

const Description: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <DialogDescription className="flex text-small-regular text-gray-700 items-center justify-center pt-2 pb-4 h-full">
      {children}
    </DialogDescription>
  )
}

const Body: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="relative flex-1">
      <div className="absolute inset-y-0 inset-x-4 sm:inset-x-8 overflow-y-auto flex flex-col pb-12">
        {children}
      </div>
    </div>
  )
}

const Footer: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ className, children }) => {
  return (
    <div
      className={`p-4 sm:p-6 flex items-center justify-end gap-x-2 sm:gap-x-4 ${className}`}
    >
      {children}
    </div>
  )
}

Modal.Title = Title
Modal.Description = Description
Modal.Body = Body
Modal.Footer = Footer

export default Modal
