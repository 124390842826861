import React from 'react'
import clsx from 'clsx'
import FormElement from '~ui/FormElement'
import type { SelectProps } from './select'
import { ChevronDownIcon } from '@heroicons/react/24/solid'

export type { SelectProps }

export const NativeSelect = React.forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      name,
      label,
      description,
      error,
      helperText,
      required,
      placeholder = 'Select...',
      noPlaceholder = false,
      containerClassName,
      children,
      ...props
    },
    ref,
  ) => {
    return (
      <FormElement
        name={name}
        error={error}
        label={label}
        description={description}
        helperText={helperText}
        required={required}
        className={clsx(containerClassName, 'relative')}
      >
        <div className="relative">
          <select
            ref={ref}
            name={name}
            {...props}
            className={clsx(
              'dark:bg-slate-900 dark:text-slate-400 block w-full rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:focus:border-slate-300 sm:text-sm',
              {
                'border-error-500 focus:border-error-500': error,
                'border-gray-300 dark:border-gray-600': !error,
              },
              props.className,
            )}
          >
            {!noPlaceholder && <option value="">{placeholder}</option>}
            {children}
          </select>
          {/* <ChevronDownIcon
            aria-hidden="true"
            className="absolute right-0 bottom-2.5 pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end text-gray-500 sm:size-4"
          /> */}
        </div>
      </FormElement>
    )
  },
)
