import React from 'react'
import clsx from 'clsx'

export const ButtonPane: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ className, children }) => {
  return (
    <div
      className={clsx(
        'flex items-center justify-end gap-x-4 border-gray-900/10 px-4 pt-4 pb-2 sm:px-0',
        className,
      )}
    >
      {children}
    </div>
  )
}
