import clsx from 'clsx'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { ErrorBoxProps } from './error-box'

export type { ErrorBoxProps }

export const ErrorBox: React.FC<ErrorBoxProps> = ({
  title,
  message,
  className,
}) => {
  return (
    <div
      className={clsx(
        'p-2 border border-error-600 bg-error-50 dark:bg-error-800 text-error-600 rounded-lg flex w-full',
        className,
      )}
    >
      <div className="flex justify-center mt-1">
        <div className="flex-grow">
          <XCircleIcon
            className="h-8 w-8 m-auto mb-2 text-error-600 dark:text-error-200"
            aria-hidden="true"
          />
        </div>
      </div>
      <div className="ml-2 mt-1">
        <p className="font-semibold text-error-800 dark:text-error-300">
          {title}
        </p>
        <p className="font-semibold text-error-600 dark:text-error-200">
          {message}
        </p>
      </div>
    </div>
  )
}
