import { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Field,
  Label,
} from '@headlessui/react'
import {
  CheckIcon,
  XMarkIcon,
  ChevronUpDownIcon,
} from '@heroicons/react/24/solid'
import { SelectOptionType } from '~ui/types'

/**
 * Make properties in T required
 */
export type MakeRequired<T, K extends keyof T> = {
  [P in K]-?: NonNullable<T[P]>
} & Omit<T, K>

type Item = MakeRequired<SelectOptionType, 'group'>
type MultiSelectProps = {
  label?: string
  placeholder?: string
  disabled?: boolean
  multiple?: boolean
  selected: string[]
  options: Item[]
  onSelect: (values: string[]) => void
}

export const GroupedMultiSelect = ({
  label,
  options,
  selected,
  placeholder,
  disabled,
  onSelect,
  multiple = true,
}: MultiSelectProps) => {
  const selectedItems = useMemo(() => {
    return options.filter((option: Item) => selected.includes(option.value))
  }, [selected, options])
  const [query, setQuery] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  const filteredItems =
    query === ''
      ? options
      : options.filter(
          (item) =>
            item.label.toLowerCase().includes(query.toLowerCase()) ||
            item.group.toLowerCase().includes(query.toLowerCase()),
        )

  const groupedItems = filteredItems.reduce(
    (acc, item) => {
      if (!acc[item.group]) {
        acc[item.group] = []
      }
      acc[item.group].push(item)
      return acc
    },
    {} as Record<string, Item[]>,
  )

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [selectedItems])
  const handleRemove = useCallback(
    (value: string) => {
      const updatedSelection = selected.filter(
        (option: string) => option !== value,
      )
      onSelect(updatedSelection)
    },
    [onSelect, selected],
  )
  const handleChange = useCallback(
    (value: Item | Item[] | null) => {
      if (Array.isArray(value)) {
        // Multiple selection mode - we set selected values directly.
        onSelect(value?.map((v) => v.value) || [])
      } else {
        // Single value - check if already selected.
        if (!selected.includes(value?.value || '')) {
          // Add only if not already selected.
          onSelect(
            value
              ? multiple
                ? [...selected, value.value]
                : [value.value]
              : [],
          )
        } else {
          // Remove if already selected.
          onSelect(selected.filter((option) => option !== value?.value))
        }
      }
    },
    [onSelect, selected, multiple],
  )
  return (
    <Field disabled={disabled} className="w-full mx-auto">
      <Combobox
        value={selectedItems}
        onChange={handleChange}
        multiple={multiple}
        as="div"
        className="space-y-1"
      >
        <>
          {label && (
            <Label className="block text-sm leading-5 font-medium text-gray-700 dark:text-slate-400">
              {label}
            </Label>
          )}
          <div className="relative mt-1">
            <div className="cursor-default relative w-full flex justify-start items-center flex-wrap gap-2 rounded-md border pl-3 pr-10 py-2 text-left focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5 bg-white border-gray-300 focus:shadow-outline-blue focus:border-blue-300 dark:bg-slate-900 dark:border-slate-500 dark:focus:shadow-outline-slate dark:focus:border-slate-700">
              {/* <div className="flex flex-wrap items-center p-1 gap-1"> */}
              {selectedItems.map((item) => (
                <div
                  key={item.value}
                  className="inline-flex items-center px-2 py-1 mr-1 rounded text-xs text-white bg-green-600 dark:text-slate-200"
                  // className="m-1 inline-flex items-center rounded-full bg-teal-100 py-0.5 pl-2 pr-0.5 text-sm font-medium text-teal-700"
                  title={`${item.label} id: ${item.value}`}
                >
                  {item.group} {item.label}
                  {/* <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault()
                          handleRemove(item.value)
                        }}
                        className="ml-1 rounded-full cursor-pointer bg-gray-100 dark:bg-slate-200 dark:text-slate-900"
                      >
                        <span className="sr-only">Remove {item.label}</span>
                        <XMarkIcon className="h-3 w-3" aria-hidden="true" />
                      </button> */}
                  <div
                    className="ml-1 rounded-full cursor-pointer bg-gray-100 dark:bg-slate-200 dark:text-slate-900"
                    onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                      e.stopPropagation()
                      e.preventDefault()
                      handleRemove(item.value)
                    }}
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L10 8.58579L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L11.4142 10L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L10 11.4142L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L8.58579 10L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z"
                        fill="#15803d"
                      />
                    </svg>
                  </div>
                </div>
              ))}
              {/* </div> */}
              <ComboboxButton className=" w-full">
                <ComboboxInput
                  className="dark:bg-slate-900 dark:focus:bg-slate-900 dark:text-slate-400 w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                  onChange={(event) => setQuery(event.target.value)}
                  displayValue={(items: Item[]) => ''}
                  placeholder={placeholder}
                  ref={inputRef}
                />
              </ComboboxButton>

              <ChevronUpDownIcon
                className="absolute right-2 h-5 w-5 text-gray-400 "
                aria-hidden="true"
              />
            </div>
            <ComboboxOptions className="absolute max-h-60 rounded-md py-1 text-base leading-6  overflow-auto focus:outline-none sm:text-sm sm:leading-5 absolute mt-1 w-full shadow-lg z-10 border border-top-1 bg-white dark:bg-slate-800">
              {Object.entries(groupedItems).map(([group, groupItems]) => (
                <div key={group}>
                  <div className="px-3 py-2 text-xs font-semibold text-gray-500 bg-gray-100 dark:bg-slate-700">
                    {group}
                  </div>
                  {groupItems.map((item) => (
                    <ComboboxOption
                      key={item.value}
                      className={({ focus }) =>
                        `cursor-default select-none relative py-2 pl-8 pr-4  ${
                          focus
                            ? 'text-white bg-blue-600 dark:bg-blue-900  dark:text-slate-300'
                            : 'text-gray-900 dark:text-slate-400 '
                        }`
                      }
                      value={item}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                          >
                            {item.label}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-1.5 ${
                                active
                                  ? 'text-white dark:text-slate-400'
                                  : 'text-blue-600 dark:text-blue-500'
                              }`}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </ComboboxOption>
                  ))}
                </div>
              ))}
            </ComboboxOptions>
          </div>
        </>
      </Combobox>
    </Field>
  )
}
